import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable, Injector, WritableSignal, effect, signal } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
    BusinessProcessCategory,
    BusinessProcessTask,
    BusinessProcessTaskActionCategory,
    TasksService,
} from 'app/api';
import { ActionDescriptor, ButtonType } from 'app/core/dialogBuilder/dialog-builder.models';
import { SaveButtonService } from 'app/core/save-button/save-button.service';
import { TranslationTabService } from 'app/core/translation-tab/translation-tab.service';
import { BusinessProcessTaskService } from 'app/services/bp/business-process.service';
import { ProcessService } from '../bp-management/process.service';
import { CurrentContent } from './current-content.model';

@Injectable({
    providedIn: 'root',
})
export class TaskProcessService extends BusinessProcessTaskService {
    executeActionSignal: WritableSignal<string>;
    specificExecuteActionSignal: WritableSignal<string> = signal(null);

    _translocoContent = signal(null);

    currentContent: WritableSignal<CurrentContent> = signal('array');
    currentModel: any;
    currentActions: ActionDescriptor[];
    editMode: WritableSignal<boolean> = signal(false);

    wrapperMode: '1' | '2' = '1';
    selectedId: WritableSignal<string> = signal(null);

    viewType: 'build' | 'build-2' = 'build';

    contentConfig: any;

    constructor(
        apiService: TasksService,
        translocoService: TranslocoService,
        saveButtonService: SaveButtonService,
        private processService: ProcessService,
        translationTabService: TranslationTabService,
        injector: Injector
    ) {
        super(apiService, saveButtonService, translationTabService, translocoService, injector);
        this.scope = 'bpTask';
        this.translocoService.selectTranslation('bpTask').subscribe(content => {
            this._translocoContent.set(content);
        });

        effect(
            () => {
                if (!this.hasActiveTranslation()) {
                    this.currentContent.set('array');
                }
            },
            {
                allowSignalWrites: true,
            }
        );
    }

    override afterGotNewModel(): void {
        this.selectedId.set(null);
        this.currentModel = null;
        if (this.viewType === 'build') {
            this.currentContent.set('array');
        } else if (this.viewType === 'build-2') {
            this.currentContent.set('idle');
        }
        if (this.contentConfig?.id === this.model().id) {
            this.reloadUsingContentConfig();
        } else {
            this.contentConfig = null;
        }
    }

    private isForcedReloading: boolean = false;
    reloadUsingContentConfig(): void {
        if (this.contentConfig) {
            this.isForcedReloading = true;

            const model = this.model().tasks.find(t => t.id === this.contentConfig.model.id);

            this.openMaintainComponent(this.contentConfig.type, model, this.contentConfig.viewType);
            this.isForcedReloading = false;
        }
    }

    /**
     * Adds the given task to the model and then calls saveBusinessProcess()
     * @param task The new BusinessProcessTask
     */
    addTask(task: BusinessProcessTask): void {
        const bp = { ...this.model() };

        if (!bp.tasks.some(t => t.id === task.id)) {
            task.id = undefined;
            bp.tasks.push(task);
            bp.tasks = bp.tasks.slice();
        } else if (!bp.tasks || !bp.tasks.length) {
            task.id = undefined;
            bp.tasks = [task];
        } else {
            const index = bp.tasks.findIndex(t => t.id === 'new');
            bp.tasks[index].id = undefined;
            task.id = undefined;
        }

        this.model.set(bp);
        this.editMode.set(false);

        this.saveBusinessProcess(
            `businessProcess${
                task.category === BusinessProcessCategory.Decission ? 'Decision' : 'Process'
            }AddedSuccessMessage`
        );
    }

    /**
     * Updates an existing task and then calls saveBusinessProcess()
     * @param task The modified BusinessProcessTask
     */
    updateTask(task: BusinessProcessTask): void {
        const bp = { ...this.model() };

        bp.tasks = bp.tasks.map((bpTask: BusinessProcessTask) => {
            if (bpTask.id === task.id) {
                return { ...bpTask, ...task };
            }
            return bpTask;
        });
        this.model.set(bp);
        this.editMode.set(false);

        this.saveBusinessProcess(
            `businessProcess${
                task.category === BusinessProcessCategory.Process ? 'Process' : 'Decision'
            }UpdatedSuccessMessage`
        )
            .toPromise()
            .then(() => {
                if (task.data.action.category === 'M_PROCESS') {
                    // Refresh the tree
                    setTimeout(() => {
                        this.processService.getBplsAsync();
                    }, 100);
                }
            });
    }

    /**
     * The function for Drag&Drop. The main aim to move the tasks as the user does while updating the 'no' property of each item.
     * @param event
     */
    drop(event: CdkDragDrop<string[]>): void {
        const bp = { ...this.model() };

        let currentIndex = event.currentIndex;
        let previousIndex = event.previousIndex;

        moveItemInArray(bp.tasks, previousIndex, currentIndex);

        bp.tasks = bp.tasks.map((bpTask: BusinessProcessTask, index: number) => {
            bpTask.no = index + 1;
            const actionNo = bpTask.data.action.no.split('.');
            bpTask.data.action.no = `${actionNo[0]}.${index + 1}`;
            return bpTask;
        });

        this.model.set(bp);
        this.saveButtonService.hasUnsavedChanges = true;
    }

    override getWbs(id: string): string {
        return this.processService.state.find(p => p.id === id).wbs;
    }

    private getDefaultDependencies(): any[] {
        const lastTaskId = this.model().tasks.length
            ? this.model().tasks[this.model().tasks.length - 1].id
            : undefined;

        if (!lastTaskId) {
            return [];
        }

        return [{ id: lastTaskId }];
    }

    /**
     * This method is used to open a dialog for new task creation.
     * Firstly asks the BE for a unique uuid for the new task, then
     * creates an empty BusinessProcessTask. Finally, pass the data to
     * the openMaintainDialog function.
     */
    onCreateTask(category: 'process' | 'integrationTask'): void {
        let no: number;
        if (this.model()?.tasks.length) {
            no = this.model().tasks.length + 1;
        } else {
            no = 1;
        }

        const wbs = this.getWbs(this.model().id);
        const dependencies = this.getDefaultDependencies();

        new Promise(resolve => setTimeout(resolve, 1)).then(() => {
            const model: BusinessProcessTask = {
                id: 'new',
                no,
                category,
                data: {
                    action: {
                        category: category === 'process' ? 'PROCESS' : (undefined as any),
                        dependencies,
                        responsible: '',
                        title1: '',
                        title2: '',
                        description: '',
                        system: '',
                        no: `${wbs}.${no}`,
                    },
                    input: [],
                    output: [],
                    text1: '',
                    text2: '',
                    files: [],
                    performance: {
                        effe: 0,
                        effi: 0,
                    },
                },
                effe: 0,
                effi: 0,
                tab: '',
            };
            this.openMaintainComponent('Create', model, category);
        });
    }
    /**
     * This method is used to open a dialog for new task creation.
     * Firstly asks the BE for a unique uuid for the new task, then
     * creates an empty BusinessProcessTask. Finally, pass the data to
     * the openMaintainDialog function.
     */
    onCreateSubprocessTask(): void {
        let no: number;
        if (this.model()?.tasks.length) {
            no = this.model().tasks.length + 1;
        } else {
            no = 1;
        }

        const wbs = this.processService.state.find(p => p.id === this.model().id).wbs;
        const category = 'process';
        const dependencies = this.getDefaultDependencies();

        new Promise(resolve => setTimeout(resolve, 1)).then(() => {
            const model: BusinessProcessTask = {
                id: 'new',
                no,
                category,
                data: {
                    action: {
                        category: 'M_PROCESS',
                        dependencies,
                        responsible: '',
                        title1: '',
                        title2: '',
                        description: '',
                        system: '',
                        no: `${wbs}.${no}`,
                    },
                    input: [],
                    output: [],
                    text1: '',
                    text2: '',
                    files: [],
                    performance: {
                        effe: 0,
                        effi: 0,
                    },
                },
                effe: 0,
                effi: 0,
                tab: '',
            };
            this.openMaintainComponent('Create', model, 'subprocessTask');
        });
    }
    /**
     * This method is used to open a dialog for new task creation.
     * Firstly asks the BE for a unique uuid for the new task, then
     * creates an empty BusinessProcessTask. Finally, pass the data to
     * the openMaintainDialog function.
     */
    onCreateSapTask(): void {
        let no: number;
        if (this.model()?.tasks.length) {
            no = this.model().tasks.length + 1;
        } else {
            no = 1;
        }

        const wbs = this.processService.state.find(p => p.id === this.model().id).wbs;
        const category = 'process';
        const dependencies = this.getDefaultDependencies();

        new Promise(resolve => setTimeout(resolve, 1)).then(() => {
            const model: BusinessProcessTask = {
                id: 'new',
                no,
                category,
                data: {
                    action: {
                        category: 'SAP_TASK',
                        dependencies,
                        responsible: '',
                        title1: '',
                        title2: '',
                        description: '',
                        system: '',
                        no: `${wbs}.${no}`,
                    },
                    input: [],
                    output: [],
                    text1: '',
                    text2: '',
                    files: [],
                    performance: {
                        effe: 0,
                        effi: 0,
                    },
                },
                effe: 0,
                effi: 0,
                tab: '',
            };
            this.openMaintainComponent('Create', model, 'sapTask');
        });
    }
    /**
     * This method is used to open a dialog for new decision creation.
     * Firstly asks the BE for a unique uuid for the new decision, then
     * creates an empty BusinessProcessTask. Finally, pass the data to
     * the openMaintainDialog function.
     */
    onCreateDecision(): void {
        let no: number;
        if (this.model()?.tasks.length) {
            no = this.model().tasks.length + 1;
        } else {
            no = 1;
        }

        const wbs = this.processService.state.find(p => p.id === this.model().id).wbs;
        const dependencies = this.getDefaultDependencies();

        new Promise(resolve => setTimeout(resolve, 1)).then(() => {
            const model: BusinessProcessTask = {
                id: 'new',
                no,
                category: 'decission',
                data: {
                    action: {
                        category: 'PROCESS',
                        dependencies,
                        responsible: '',
                        title1: '',
                        title2: '',
                        description: '',
                        system: '',
                        no: `${wbs}.${no}`,
                    },
                    files: [],
                    input: [],
                    text1: '',
                    text2: '',
                    output: [],
                    performance: {
                        effe: 0,
                        effi: 0,
                    },
                },
                effe: 0,
                effi: 0,
                tab: '',
            };
            this.openMaintainComponent('Create', model, 'decision');
        });
    }

    editorActions = signal(null);

    /**
     * Opens the dialog for maintaining tasks and decisions using DialogBuilderService.
     * @param type Type of maintanance, 'Create' or 'Edit'
     * @param model The BusinessProcessTask model to be edited
     * @param viewType The type of the presented form: 'task' or 'decission'
     */
    public openMaintainComponent(
        type: 'Create' | 'Edit',
        model: BusinessProcessTask,
        viewType: 'process' | 'integrationTask' | 'subprocessTask' | 'sapTask' | 'decision',
        force?: boolean
    ): void {
        viewType = model.category as any;
        if (model.category === 'decission') {
            viewType = 'decision';
        } else if (model.data.action.category === 'M_PROCESS') {
            viewType = 'subprocessTask';
        } else if (model.data.action.category === 'SAP_TASK') {
            viewType = 'sapTask';
        }

        if (
            !force &&
            !this.isForcedReloading &&
            this.currentModel &&
            this.currentModel.model &&
            this.currentModel.model?.id === model.id
        ) {
            return;
        }

        this.contentConfig = {
            id: this.model().id,
            type,
            model,
            viewType,
        };

        this.currentModel = {
            model,
            type,
            service: this,
        };
        this.selectedId.set(model.id);

        this.currentActions = [];
        if (!this.processService.isValidationOngoing) {
            if (this.viewType === 'build') {
                this.currentActions.push({
                    code: 'backToArray',
                    style: ButtonType.icon,
                    icon: 'mat_solid:arrow_back',
                    manualClose: true,
                });
            }
            this.currentActions.push({
                code: 'saveEditedItem',
                style: ButtonType.icon,
                icon: 'mat_solid:save',
                manualClose: true,
                automaticDisableForSave: true,
                showWarn: true,
                tooltip: this._translocoContent()['save'],
            });
        }

        if (
            !this.processService.isValidationOngoing &&
            (model.data.action.category === BusinessProcessTaskActionCategory.Process ||
                model.data.action.category === BusinessProcessTaskActionCategory.Control)
        ) {
            this.currentActions.push({
                code: 'convertStandardToSubprocess',
                style: ButtonType.icon,
                icon: 'heroicons_solid:arrow-path',
                tooltip: this._translocoContent()['convert'],
                manualClose: true,
            });
        }

        this.editorActions.set(this.currentActions);

        this.editMode.set(true);
        this.currentContent.set('switching');
        setTimeout(() => {
            this.currentContent.set(viewType);

            this._model = { ...this.model() };
        }, 100);
    }

    protected override handleAfterSave(): void {
        if (this.viewType === 'build') {
            this.currentContent.set('array');
        }

        if (this.currentModel.model.id === 'new' || this.currentModel.model.id === undefined) {
            const modelWithId = this.model().tasks[this.model().tasks.length - 1];
            this.openMaintainComponent('Edit', modelWithId, this.contentConfig.viewType);
        }
    }

    protected override handleAfterDelete(): void {
        if (this.viewType === 'build') {
            this.currentContent.set('array');
            return;
        }
        this.currentContent.set('idle');
        this.currentModel.model = null;
        this.selectedId.set(null);

        // if (this.currentModel.model.id === 'new' || !this.currentModel.model.id) {
        //     const modelWithId = this.model().tasks[this.model().tasks.length - 1];
        //     this.currentModel.model = modelWithId;
        //     this.selectedId.set(modelWithId.id);
        // }
    }
}
